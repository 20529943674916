import React, { useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImagesEntity } from "../../utils/properties";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface ImageSliderProps {
  images: ImagesEntity[];
}

const ImageSlider = ({ images }: ImageSliderProps) => {
  const slider1 = useRef<Slider | any>(null);
  const slider2 = useRef<Slider | any>(null);

  const settings1: Settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    asNavFor: slider2.current,
  };

  const settings2: Settings = {
    dots: false,
    infinite: true,
    slidesToShow: images.length > 3 ? 3 : images.length,
    slidesToScroll: 1,
    asNavFor: slider1.current,
    centerMode: true,
    focusOnSelect: true,
    arrows: false,
  };

  const [zoom, setZoom] = useState(false);
  return (
    <>
      {/* {!zoom && (
        <div className="w-96 cursor-zoom-in">
          <Slider ref={slider1} {...settings1}>
            {images.map((ele, index) => (
              <div key={index} onClick={() => setZoom(true)}>
                <img
                  className={`w-full ${
                    !zoom ? "lg:max-h-80" : "lg:h-[800px]"
                  } object-cover`}
                  src={
                    ele.url
                      ? ele.url
                      : "https://betaapi.truevals.com/uploads/properties/Main_Image.jpeg"
                  }
                  alt=""
                />
              </div>
            ))}
          </Slider>
          <Slider ref={slider2} {...settings2}>
            {images.map((ele, index) => (
              <div key={index} >
                <img
                  className={`${
                    !zoom && "h-20 w-full object-cover cursor-pointer"
                  }`}
                  src={
                    ele.url
                      ? ele.url
                      : "https://betaapi.truevals.com/uploads/properties/Main_Image.jpeg"
                  }
                  alt=""
                />
              </div>
            ))}
          </Slider>
        </div>
      )}
      {zoom && ( */}
        <div className={`${zoom && 'fixed top-0 bottom-0 right-0 left-0 p-[130px] z-[99999999]'} ${!zoom && 'w-[500px] cursor-zoom-in]'}`}>
          <div
            className={`${zoom && 'fixed top-0 left-0 right-0 bottom-0 bg-black-373933 cursor-zoom-out'} ${!zoom && 'hidden'} `}
            onClick={() => setZoom(false)}
          ></div>
          <div className={`${zoom && 'fixed top-20 right-20'} ${!zoom && 'hidden'}`} onClick={() => setZoom(false)}>
            <FontAwesomeIcon icon={faTimes} className="text-2xl cursor-pointer" />
          </div>
          <div className="relative">
            <div>
              <Slider ref={slider1} {...settings1}>
                {images.map((ele, index) => (
                  <div key={index}  onClick={() => setZoom(true)} className="cursor-zoom-in">
                    <img
                      className={`w-full ${
                        !zoom ? "lg:max-h-96" : "lg:h-[800px]"
                      } ${zoom && "object-contain"} ${!zoom && "object-cover"}`}
                      src={
                        ele.url
                          ? ele.url
                          : "https://betaapi.truevals.com/uploads/properties/Main_Image.jpeg"
                      }
                      alt=""
                    />
                  </div>
                ))}
              </Slider>
             {images.length > 3 && <Slider
                ref={slider2}
                {...settings2}
                className={`${zoom && "w-1/2 m-auto"} ${!zoom && "w-full"}`}
              >
                {images.map((ele, index) => (
                  <div key={index}>
                    <img
                      className={`${
                        zoom && "h-52 w-full object-cover cursor-pointer"
                      } ${!zoom && "h-20 w-full object-cover cursor-pointer"}`}
                      src={
                        ele.url
                          ? ele.url
                          : "https://betaapi.truevals.com/uploads/properties/Main_Image.jpeg"
                      }
                      alt=""
                    />
                  </div>
                ))}
              </Slider>}
            </div>
          </div>
        </div>
      {/* )} */}
    </>
  );
};

export default ImageSlider;
