import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import Footer from "./components/headerFooter/Footer";
import Header from "./components/headerFooter/Header";
import Userlogin from "./pages/Login";
import Userregister from "./pages/Register";
import Resetpwd from "./pages/Resetpwd";
import Property from "./pages/Property";
import PropertyListing from "./pages/PropertyListing";
import VerifyOtp from "./pages/VerifyOtp";
import VerifyOtpPassword from "./pages/VerifyOtpPassword";
import useThemeStore from "./store/themeStore";
import { getTheme, ThemeProps } from "./utils/theme";
import WhiteLabel from "./pages/WhiteLabel";
import Blog from "./pages/Blog";
import ScrollToTop from "./ScrollToTop";
function App() {
  const setTheme = useThemeStore((state) => state.setTheme);
  const [loading, setLoading] = useState(true);

  const fetchTheme = async () => {
    const response = await getTheme();
    if (response) {
      setTheme(response);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTheme();
  }, []);
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  return (
    <div className="App">
      {!loading && (
        <Router basename={"/"}>
          <ScrollToTop>
            <Fragment>
              <Header />
              <Routes>
                <Route
                  path="/"
                  element={
                    getThemes && getThemes.hybrid ? (
                      <PropertyListing />
                    ) : (
                      <WhiteLabel />
                    )
                  }
                />
                <Route path="/listing/:id" element={<Property />} />
                <Route path="/listing/:id/failure" element={<Property />} />
                <Route path="/listing/:id/success" element={<Property />} />
                <Route path="/listing" element={<PropertyListing />} />
                <Route path="/login" element={<Userlogin />} />
                <Route path="/register" element={<Userregister />} />
                <Route path="/resetPassword" element={<Resetpwd />} />
                <Route
                  path="/verifyPasswordOtp"
                  element={<VerifyOtpPassword />}
                />
                <Route path="/verifyotp" element={<VerifyOtp />} />
                <Route path="/blog" element={<Blog />} />
              </Routes>
              <Footer />
            </Fragment>
          </ScrollToTop>
        </Router>
      )}
      {loading && (
        <div className="flex justify-center items-center h-screen">Loading</div>
      )}
    </div>
  );
}

export default App;
