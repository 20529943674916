import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReviewStars from "../components/ReviewStars";
import Rightarrow from "../images/icons/arrow-right-solid.svg";
import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";

function WhiteLabel() {
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );
  return (
    <div
      className="Home bg-primarycolor pt-28"
      style={{ background: getThemes && getThemes.color1 }}
    >
      {/* <<============Banner============>> */}
      <div className="w-full">
        <div className="max-w-7xl m-auto grid lg:grid-cols-5 grid-cols-1 lg:pt-16 pt-5 pb-20 gap-x-4 justify-center items-center">
          <div className="p-5 col-span-3">
            <h3 className="lg:text-5xl text-xl font-poppins lg:font-semibold font-medium text-left text-white-f2f9fa lg:pb-7 pb-3 lg:leading-snug lg:pr-12 lg:tracking-wide tracking-wider">
              Build a high-yielding residential property portfolio with ease
            </h3>
            <p className="text-base font-poppins font-normal text-left text-white-f2f9fa leading-7 tracking-wide lg:pb-3 pb-3">
              We use innovative, proprietary technology to help ambitious
              investors build, grow and manage high-yielding, sustainable
              property portfolios. Our hassle-free investment experience allows
              you to sit back and watch your wealth grow.
            </p>
            <div className="grid lg:grid-cols-3 grid-cols-1 pt-5">
              <Link className="text-white-f2f9fa" to="/listing">
                <div
                  className="py-2.5 w-56 rounded-full bg-btnscolor hover:bg-white-f2f9fa hover:text-white-f2f9fa transition ease-in-out delay-150 hover:scale-110 duration-300"
                  style={{ background: getThemes && getThemes.color2 }}
                >
                  Search Properties
                </div>
              </Link>
              <Link className="text-white-f2f9fa  " to="#">
                <div className="w-56 flex gap-x-3 px-6 py-2.5 rounded-full transition ease-in-out delay-150 hover:scale-110 duration-300 justify-center">
                  Learn More <img src={Rightarrow} className="w-6 h-6" />
                </div>
              </Link>
            </div>
          </div>
          <div className="col-span-2">
            <img
              src="//www.trustbricks.co.uk/wp-content/uploads/2022/07/Trustbricks.png"
              className="w-full"
              alt=""
            />
          </div>
        </div>
      </div>
      {/* <<============Video============>> */}
      <div className="w-full m-auto bg-white-f2f9fa">
        <div className="max-w-7xl m-auto py-16">
          <div className="m-auto">
            <h3 className="text-4xl font-poppins font-semibold text-primarycolor w-full text-center pb-5">
              How it works...
            </h3>
            <div className="flex flex-wrap justify-center lg:pr-14 px-5">
              <iframe
                title="vimeo-player"
                src="https://www.veed.io/embed/62aaeb6c-e704-49e8-893b-9768f015e190?watermark=0&color=default&sharing=0&title=0"
                width="640"
                height="360"
                frameBorder={0}
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="py-5">
            <div className="flex flex-wrap items-center">
              <div className="lg:w-1/2 w-full">
                <img
                  src="//www.trustbricks.co.uk/wp-content/uploads/2023/03/belowvideo1.png"
                  className="w-80 m-auto"
                  alt=""
                />
              </div>
              <div className="lg:w-1/2 w-full font-poppins lg:text-left text-center pt-8 lg:pr-10 px-5">
                <h3 className="text-2xl tracking-wide pb-3">
                  Select your budget, preferred locations and yield expectations
                </h3>
                <p className="text-base leading-7 tracking-wide pb-3">
                  Using innovative, property technology and data-analysis tools
                  we’ll instantly source a fully vetted list of profitable
                  investment opportunities that match your search criteria.
                  These will be packaged up into an easy-to-understand document
                  ready for you to approve.
                </p>
                <p className="text-sm leading-7 tracking-wide">
                  Rest assured, all properties go through a full, comprehensive
                  due diligence process. You’ll receive a full financial
                  break-down, comparable properties in the market, information
                  about the local area, refurbishment requirements (if any) and
                  estate agent valuations for sales and lettings.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap items-center">
            <div className="lg:w-1/2 w-full font-poppins lg:text-left text-center pt-8 lg:pr-10 px-5">
                <h3 className="text-2xl tracking-wide pb-3">
                  The entire process is taken care of for you - from sourcing to
                  letting
                </h3>
                <p className="text-base leading-7 tracking-wide pb-3">
                  The entire deal will be managed by a team of experts. Finding
                  the properties and carrying out full due diligence, managing
                  the buying process to get you the best deal, pricing and
                  managing the refurbishment, sourcing tenants, letting
                  management, even your bookkeeping and accounts.
                </p>
                <p className="text-sm leading-7 tracking-wide">
                  You’ll be kept up to date throughout the entire process – as
                  little or as much as you want to be. Building a residential
                  property portfolio is not only easy, but enjoyable. We’ll help
                  you enjoy a hassle-free investment journey which allows you to
                  divert your attention elsewhere.
                </p>
              </div>
              <div className="lg:w-1/2 w-full">
                <img
                  src="//www.trustbricks.co.uk/wp-content/uploads/2023/03/belowvideo2.png"
                  className="w-80 m-auto"
                  alt=""
                />
              </div>
            </div>
            <div className="flex flex-wrap items-center">
              <div className="lg:w-1/2 w-full">
                <img
                  src="//www.trustbricks.co.uk/wp-content/uploads/2023/03/belowvideo3.png"
                  className="w-80 m-auto"
                  alt=""
                />
              </div>
              <div className="lg:w-1/2 w-full font-poppins lg:text-left text-center pt-8 lg:pr-10 px-5">
                <h3 className="text-2xl tracking-wide pb-3">
                  Watch your residential property portfolio, and wealth, grow!
                </h3>
                <p className="text-base leading-7 tracking-wide pb-3">
                  There is no limit to the number of properties you can hold
                  within your portfolio. You’ll always own the assets, fully
                  benefit from the capital appreciation, as well as the cashflow
                  from renting.
                </p>
                <p className="text-sm leading-7 tracking-wide">
                  Ready to start your property investment journey? Get started
                  today to tell us your search criteria and we’ll share a fully
                  bespoke, curated list of fully vetted properties that match
                  your budget, location and profitability requirements.
                </p>
              </div>
            </div>
            <div className="justify-center flex pt-14">
              <Link className="text-white-f2f9fa" to="/listing">
                <div
                  className="py-2.5 w-56 rounded-full bg-btnscolor hover:bg-white-f2f9fa hover:text-white-f2f9fa transition ease-in-out delay-150 hover:scale-110 duration-300"
                  style={{ background: getThemes && getThemes.color2 }}
                >
                  Learn More
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <<============4 colr============>> */}
      <div className="w-full m-auto bg-white-f2f9fa">
        <div className="max-w-7xl m-auto lg:py-24 py-14 text-primarycolor font-poppins lg:px-0 px-5">
          <h3 className="lg:text-4xl text-2xl font-semibold text-center leading-tight pb-6">
            Buy-to-let investments -<br></br>a reliable way to get rich while
            you sleep
          </h3>
          <h5 className="lg:text-2xl text-lg font-medium tracking-wide">
            Buy-to-let investment properties are among the most reliable and
            profitable investments.
          </h5>
          <div className="pt-14">
            <div className="grid lg:grid-cols-4 grid-cols-1 gap-x-5 m-auto">
              {/* <<============section============>> */}
              <div className="gap-y-3 flex flex-wrap justify-center">
                <img
                  src="//www.trustbricks.co.uk/wp-content/uploads/2023/03/First-time-Investors.png"
                  className="lg:w-40 w-80 lg:p-0 p-10 m-auto"
                  alt=""
                />
                <h3 className="font-roboto text-xl font-medium">
                  Invest in a stable market
                </h3>
                <p className="font-roboto text-base font-normal tracking-wide pb-4">
                  48% of rental properties are owned by portfolio landlords (10
                  or more properties)
                </p>
              </div>
              {/* <<============section============>> */}
              {/* <<============section============>> */}
              <div className="gap-y-3 flex flex-wrap justify-center">
                <img
                  src="//www.trustbricks.co.uk/wp-content/uploads/2023/03/Corporate-Partnerships.png"
                  className="lg:w-40 w-80 lg:p-0 p-10 m-auto"
                  alt=""
                />
                <h3 className="font-roboto text-xl font-medium">
                  Benefit from predictability
                </h3>
                <p className="font-roboto text-base font-normal tracking-wide pb-4">
                  Investing in residential property is less volatile than other
                  markets
                </p>
              </div>
              {/* <<============section============>> */}
              {/* <<============section============>> */}
              <div className="gap-y-3 flex flex-wrap justify-center">
                <img
                  src="//www.trustbricks.co.uk/wp-content/uploads/2023/03/Corporate-Partnerships.png"
                  className="lg:w-40 w-80 lg:p-0 p-10 m-auto"
                  alt=""
                />
                <h3 className="font-roboto text-xl font-medium">
                  See double profitability
                </h3>
                <p className="font-roboto text-base font-normal tracking-wide pb-4">
                  Receive rent monthly, but also an increase in property value
                  over-time
                </p>
              </div>
              {/* <<============section============>> */}
              {/* <<============section============>> */}
              <div className="gap-y-3 flex flex-wrap justify-center">
                <img
                  src="//www.trustbricks.co.uk/wp-content/uploads/2023/03/Our-Tech-Your-Branding.png"
                  className="lg:w-40 w-80 lg:p-0 p-10 m-auto"
                  alt=""
                />
                <h3 className="font-roboto text-xl font-medium">
                  Little effort required
                </h3>
                <p className="font-roboto text-base font-normal tracking-wide pb-4">
                  We take care of everything so you can divert your attention
                  elsewhere
                </p>
              </div>
              {/* <<============section============>> */}
            </div>
            <div className="justify-center flex pt-5">
             
                <div
                  className="py-2.5 w-56 rounded-full text-white-f2f9fa cursor-pointer transition ease-in-out delay-150 hover:scale-110 duration-300" data-hs-overlay="#hs-large-modal"
                  style={{ background: getThemes && getThemes.color2 }}
                >
                  Get Started
                </div>
            
            </div>
          </div>
        </div>
      </div>
      {/* <<============reviews============>> */}
      <div className="w-full m-auto bg-white-f2f9fa">
        <div className="max-w-7xl m-auto lg:py-24 py-14 text-primarycolor font-poppins px-5">
          <h3 className="lg:text-4xl text-2xl font-semibold text-center leading-tight pb-6">
            What do our portfolio landlords think?
          </h3>
          <div className="pt-6">
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-x-5 m-auto">
              {/* <<============section============>> */}
              <div className="justify-center">
                <h3 className="font-poppins text-sm font-normal leading-6 pb-4">
                  This site takes the stress out of finding properties for
                  myself. The team is easy to talk to and always quick to
                  respond. I have purchased multiple properties and will
                  continue to do so in the future.
                </h3>
                <ReviewStars />
                <p className="font-roboto text-base font-normal tracking-wide pb-4">
                  Mr R
                </p>
              </div>

              <div className="justify-center">
                <h3 className="font-poppins text-sm font-normal leading-6 pb-4">
                  This business is like a family who welcome you into the fold.
                  They only work with trusted parties so you’re coming into a
                  group with a wealth of knowledge and trust. One of the best
                  decisions I’ve ever made.
                </h3>
                <ReviewStars />
                <p className="font-roboto text-base font-normal tracking-wide pb-4">
                  Mrs M
                </p>
              </div>

              <div className="justify-center">
                <h3 className="font-poppins text-sm font-normal leading-6 pb-4">
                  I sourced my first deal as a property newbie and the service I
                  received was exceptional. I was led through the entire process
                  step-by-step and the communication was always thorough and
                  consistent. Highly recommend.
                </h3>
                <ReviewStars />
                <p className="font-roboto text-base font-normal tracking-wide pb-4">
                  Mr A. K
                </p>
              </div>
              {/* <<============section============>> */}
            </div>
          </div>
        </div>
      </div>
      {/* <<============ready to get started============>> */}
      <div className="w-full m-auto bg-[#F8F8F9]">
        <div className="max-w-7xl m-auto py-24 text-primarycolor font-poppins lg:px-0 px-5">
          <h3 className="lg:text-4xl text-2xl font-semibold text-center leading-tight pb-6">
            Ready to get started?
          </h3>
          <h5 className="text-xl font-normal tracking-wide">
            Join our investor community today and starting browsing our
            high-yielding investment opportunities
          </h5>
          <div className="justify-center flex pt-5">
             
             <div
               className="py-2.5 w-56 rounded-full text-white-f2f9fa cursor-pointer transition ease-in-out delay-150 hover:scale-110 duration-300" data-hs-overlay="#hs-large-modal"
               style={{ background: getThemes && getThemes.color2 }}
             >
             Sign Up Today
             </div>
         
         </div>
        </div>
      </div>
    </div>
  );
}

export default WhiteLabel;
