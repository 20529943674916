import {
  useCallback,
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
} from "react";
import { Helmet } from "react-helmet";
import PropertyCard from "../components/property/PropertyCard";
import { useInView } from "react-intersection-observer";

import {
  Filter,
  getFeaturedProperties,
  getProperties,
  numberFormat,
  PropertiesExtraResult,
  Property,
} from "../utils/properties";
import MultiRangeSlider from "multi-range-slider-react";
import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";
import PropertyCardFeatured from "../components/property/PropertyCardFeatured";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

function PropertyListing() {
  const [properties, setProperties] = useState<Property[]>();
  const [filterProperties, setFilterProperties] = useState<Filter>();
  const [featuredProperties, setFeaturedProperties] =
    useState<PropertiesExtraResult>();
  const [loading, setLoading] = useState(true);
  const [minValue, set_minValue] = useState(0);
  const [maxValue, set_maxValue] = useState(100);
  const [minBedroom, set_minBedroom] = useState(0);
  const [maxBedroom, set_maxBedroom] = useState(10);
  const [propertyType, setPropertyType] = useState("");
  const [region, setRegion] = useState("");
  const [town, setTown] = useState("");
  const [strategy, setStrategy] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sortDate, setSortDate] = useState("Latest");
  const [sortPrice, setSortPrice] = useState("By Price");
  const [sortYield, setSortYield] = useState("By Yield");
  const [sortDiscount, setSortDiscount] = useState("By Discount");
  const [isReserved, setIsReserved] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(60);

  const [minYield, set_minYield] = useState(0);
  const [maxYield, set_maxYield] = useState(1000);

  const handleInput = (e: any) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };

  const handleYieldInput = (e: any) => {
    set_minYield(e.minValue);
    set_maxYield(e.maxValue);
  };

  const handleBedInput = (e: any) => {
    set_minBedroom(e.minValue);
    set_maxBedroom(e.maxValue);
  };

  useEffect(() => {
    const fetchProperties = async () => {
      setLoadFilter(true);
      const filter = {
        ...(region && { region }),
        ...(town && { town }),
        ...(propertyType && { propertyType }),
        ...(strategy && { strategy }),
        ...(minValue !== 0 && minValue !== maxValue && { minPrice: minValue }),
        ...(maxValue !== 100 && minValue !== maxValue && { maxPrice: maxValue }),
        ...(minYield !== 0 &&
          minYield !== maxYield && { minGrossYield: minYield }),
        ...(maxYield !== 100 &&
          minYield !== maxYield && { maxGrossYield: maxYield }),
        ...(searchKeyword && { searchKeyword }),
        ...(maxBedroom !== 10 &&
          minBedroom !== maxBedroom && { maxBedRoom: maxBedroom }),
        ...(minBedroom !== 0 &&
          maxBedroom !== minBedroom && { minBedRoom: minBedroom - 1 }),
        ...(sortDate !== "By Date" && { sortDate }),
        ...(sortPrice !== "By Price" && { sortPrice }),
        ...(sortYield !== "By Yield" && { sortYield }),
        ...(sortDiscount !== "By Discount" && { sortDiscount }),
        isReserved,
      };
  
      setFilter(filter);
  
      const pr = await getProperties({ ...filter, page: page, limit: limit });
      setProperties(pr.results);
      setFilterProperties(pr.filter);
      setLoadFilter(false);
      setLoading(false);
    };
    fetchProperties();
  }, []);

  useEffect(() => {
    const fetchFeaturedProperties = async () => {
      const pr = await getFeaturedProperties();
      setFeaturedProperties(pr);
    };
    fetchFeaturedProperties();
  }, []);

  const [filter, setFilter] = useState({});

  const filterAction = async () => {
    setLoadFilter(true);
    const filter = {
      ...(region && { region }),
      ...(town && { town }),
      ...(propertyType && { propertyType }),
      ...(strategy && { strategy }),
      ...(minValue !== 0 && minValue !== maxValue && { minPrice: minValue }),
      ...(maxValue !== 100 && minValue !== maxValue && { maxPrice: maxValue }),
      ...(minYield !== 0 &&
        minYield !== maxYield && { minGrossYield: minYield }),
      ...(maxYield !== 100 &&
        minYield !== maxYield && { maxGrossYield: maxYield }),
      ...(searchKeyword && { searchKeyword }),
      ...(maxBedroom !== 10 &&
        minBedroom !== maxBedroom && { maxBedRoom: maxBedroom }),
      ...(minBedroom !== 0 &&
        maxBedroom !== minBedroom && { minBedRoom: minBedroom - 1 }),
      ...(sortDate !== "By Date" && { sortDate }),
      ...(sortPrice !== "By Price" && { sortPrice }),
      ...(sortYield !== "By Yield" && { sortYield }),
      ...(sortDiscount !== "By Discount" && { sortDiscount }),
      isReserved,
    };

    setFilter(filter);

    const pr = await getProperties({ ...filter, page: page, limit: limit });
    setProperties(pr.results);
    setPage(pr.pagination.next);
    setFilterProperties(pr.filter);
    setLoadFilter(false);
  };

  const [loadFilter, setLoadFilter] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const viewMore = async () => {
    if (properties && properties.length > 0) {
      setLoadingMore(true);
      if (!loadingMore) {
        const pr = await getProperties({ ...filter, page: page, limit: limit });

        if (pr.pagination.next !== page) {
          setProperties((prev) => [...prev!, ...pr.results]);
        }
        setPage(pr.pagination.next);

        setLoadingMore(false);
      }
    }
  };

  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  const setSortCriteria = (
    sortType: string,
    sortDate: string,
    sortYield: string,
    sortDiscount: string
  ) => {
    setSortPrice(sortType);
    setSortDate(sortDate);
    setSortYield(sortYield);
    setSortDiscount(sortDiscount);
  };

  const sortPriceAction = (data: string) => {
    if (data === "By Price") {
      setSortCriteria("By Price", "By Date", "By Yield", "By Discount");
    } else if (data === "Lowest") {
      setSortCriteria("Lowest", "By Date", "By Yield", "By Discount");
    } else if (data === "Highest") {
      setSortCriteria("Highest", "By Date", "By Yield", "By Discount");
    }
  };

  const sortDateAction = (data: string) => {
    if (data === "By Date") {
      setSortCriteria("By Price", "By Date", "By Yield", "By Discount");
    } else if (data === "Oldest") {
      setSortCriteria("Oldest", "By Price", "By Yield", "By Discount");
    } else if (data === "Latest") {
      setSortCriteria("Latest", "By Price", "By Yield", "By Discount");
    }
  };

  const sortYieldAction = (data: string) => {
    if (data === "By Yield") {
      setSortCriteria("By Price", "By Date", "By Yield", "By Discount");
    } else if (data === "Lowest") {
      setSortCriteria("Lowest", "By Date", "By Price", "By Discount");
    } else if (data === "Highest") {
      setSortCriteria("Highest", "By Date", "By Price", "By Discount");
    }
  };

  const sortDiscountAction = (data: string) => {
    if (data === "By Discount") {
      setSortCriteria("By Price", "By Date", "By Yield", "By Discount");
    } else if (data === "Lowest") {
      setSortCriteria("By Yield", "By Date", "By Price", "Lowest");
    } else if (data === "Highest") {
      setSortCriteria("By Yield", "By Date", "By Price", "Highest");
    }
  };

  const [viewMoreButtonRef, inView] = useInView({});

  useEffect(() => {
    viewMore();
  }, [inView]);

  interface PropsLoading {
    color: string;
  }
  const LoadingDiv = ({ color }: PropsLoading) => {
    return (
      <div className="h-10 w-10">
        <svg
          version="1.1"
          id="L9"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enable-background="new 0 0 0 0"
          xmlSpace="preserve"
        >
          <path
            fill={color}
            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>
    );
  };

  return (
    <div className="PropertiesListing pt-20">
      <Helmet>
        <title>Property Listing</title>
      </Helmet>
      {/* <<=================includes filter=================>> */}
      {!loading && (
        <div className="py-5 pt-10">
          <div className="border max-w-7xl m-auto p-5 rounded-xl shadow-md">
            <div className="grid lg:grid-cols-5 grid-cols-1 lg:gap-x-2 gap-1 max-w-7xl m-auto justify-center items-center lg:px-0 px-5">
              <div className="px-2">
                <label
                  htmlFor="hs-select-label"
                  className="block text-base font-normal mb-2 text-primarycolor"
                  style={{
                    color:
                      getThemes && getThemes.color1 == "rgba(255,255,255,1)"
                        ? "rgba(180,180,180,1)"
                        : getThemes.color1,
                  }}
                >
                  Region
                </label>
                <select
                  id="hs-select-label"
                  className="py-3 px-4 pr-9 block w-full border-primarycolor border-2 rounded-full text-sm bg-white-f2f9fa"
                  style={{
                    borderColor:
                      getThemes && getThemes.color1 == "rgba(255,255,255,1)"
                        ? "rgba(180,180,180,1)"
                        : getThemes.color1,
                  }}
                  defaultValue={region}
                  onChange={(e) => {
                    setRegion(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  {properties &&
                    filterProperties?.regions.map((ele, index) => (
                      <option key={index} value={ele}>
                        {ele}
                      </option>
                    ))}
                </select>
              </div>
              <div className="px-2">
                <label
                  htmlFor="hs-select-label"
                  className="block text-base font-normal mb-2 text-primarycolor"
                  style={{
                    color:
                      getThemes && getThemes.color1 == "rgba(255,255,255,1)"
                        ? "rgba(180,180,180,1)"
                        : getThemes.color1,
                  }}
                >
                  Town
                </label>
                <select
                  id="hs-select-label"
                  className="py-3 px-4 pr-9 block w-full border-primarycolor border-2 rounded-full text-sm bg-white-f2f9fa"
                  style={{
                    borderColor:
                      getThemes && getThemes.color1 == "rgba(255,255,255,1)"
                        ? "rgba(180,180,180,1)"
                        : getThemes.color1,
                  }}
                  defaultValue={town}
                  onChange={(e) => {
                    setTown(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  {properties &&
                    filterProperties?.towns.map((ele, index) => (
                      <option key={index} value={ele}>
                        {ele}
                      </option>
                    ))}
                </select>
              </div>
              <div className="px-2">
                <label
                  htmlFor="hs-select-label"
                  className="block text-base font-normal mb-2 text-primarycolor"
                  style={{
                    color:
                      getThemes && getThemes.color1 == "rgba(255,255,255,1)"
                        ? "rgba(180,180,180,1)"
                        : getThemes.color1,
                  }}
                >
                  Strategy
                </label>
                <select
                  id="hs-select-label"
                  className="py-3 px-4 pr-9 block w-full border-primarycolor border-2 rounded-full text-sm bg-white-f2f9fa"
                  style={{
                    borderColor:
                      getThemes && getThemes.color1 == "rgba(255,255,255,1)"
                        ? "rgba(180,180,180,1)"
                        : getThemes.color1,
                  }}
                  defaultValue={strategy}
                  onChange={(e) => {
                    setStrategy(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  {properties &&
                    filterProperties?.strategy.map((ele, index) => (
                      <option key={index} value={ele}>
                        {ele}
                      </option>
                    ))}
                </select>
              </div>
              <div className="px-2">
                <label
                  htmlFor="hs-select-label"
                  className="block text-base font-normal mb-2 text-primarycolor"
                  style={{
                    color:
                      getThemes && getThemes.color1 == "rgba(255,255,255,1)"
                        ? "rgba(180,180,180,1)"
                        : getThemes.color1,
                  }}
                >
                  Property Type
                </label>
                <select
                  id="hs-select-label"
                  className="py-3 px-4 pr-9 block w-full border-primarycolor border-2 rounded-full text-sm bg-white-f2f9fa"
                  style={{
                    borderColor:
                      getThemes && getThemes.color1 == "rgba(255,255,255,1)"
                        ? "rgba(180,180,180,1)"
                        : getThemes.color1,
                  }}
                  defaultValue={propertyType}
                  onChange={(e) => {
                    setPropertyType(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  {properties &&
                    filterProperties?.propertyType.map((ele, index) => (
                      <option key={index} value={ele}>
                        {ele}
                      </option>
                    ))}
                </select>
              </div>

              <div className="px-2 mt-8">
                <div className="flex rounded-md shadow-sm gap-2">
                  <input
                    type="text"
                    id="hs-trailing-button-add-on-with-icon"
                    name="hs-trailing-button-add-on-with-icon"
                    className="py-3 px-4 block w-full border-primarycolor border-2 rounded-full text-sm"
                    style={{
                      borderColor:
                        getThemes && getThemes.color1 == "rgba(255,255,255,1)"
                          ? "rgba(180,180,180,1)"
                          : getThemes.color1,
                    }}
                    placeholder="Street name or Postcode (eg CH or CH1)"
                    onInput={(e) => setSearchKeyword(e.currentTarget.value)}
                  />
                  <button
                    type="button"
                    className="inline-flex flex-shrink-0 text-white-f2f9fa justify-center items-center h-[2.875rem] w-[2.875rem] rounded-full font-semibold bg-primarycolor hover:bg-primarycolor/[.7] text-sm"
                    style={{ backgroundColor: getThemes && getThemes.color1 }}
                    onClick={() => filterAction()}
                  >
                    {!loadFilter && (
                      <svg
                        className="h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    )}
                    {loadFilter && <LoadingDiv color={"#fff"} />}
                  </button>
                </div>
              </div>
            </div>
            <div
              className="mt-5 grid lg:grid-cols-3 grid-cols-1 lg:gap-x-10 gap-2 max-w-7xl m-auto justify-center items-center lg:px-0 px-5 "
              id="hs-show-hide-collapse-heading"
              aria-labelledby="hs-show-hide-collapse"
            >
              <div className="px-2">
                <div className="relative pt-1">
                  <label
                    htmlFor="customRange1"
                    className="form-label block text-base font-normal mb-2 text-primarycolor"
                    style={{
                      color:
                        getThemes && getThemes.color1 == "rgba(255,255,255,1)"
                          ? "rgba(180,180,180,1)"
                          : getThemes.color1,
                    }}
                  >
                    Price
                  </label>
                  <MultiRangeSlider
                    min={filterProperties?.minPrice as unknown as string}
                    max={filterProperties?.maxPrice as unknown as string}
                    step={10000}
                    minValue={
                      filterProperties?.minPrice !== undefined
                        ? filterProperties?.minPrice
                        : minValue
                    }
                    maxValue={
                      filterProperties?.maxPrice !== undefined
                        ? filterProperties?.maxPrice
                        : maxValue
                    }
                    onInput={(e) => {
                      handleInput(e);
                    }}
                    ruler={false}
                    label={false}
                    barInnerColor={
                      getThemes && getThemes.color1 == "rgba(255,255,255,1)"
                        ? "rgba(180,180,180,1)"
                        : getThemes.color1
                    }
                    thumbLeftColor={getThemes && getThemes.color2}
                    thumbRightColor={getThemes && getThemes.color2}
                    className=""
                  />
                  <div className="flex justify-items-center justify-center gap-x-5">
                    <span className="">
                      £{numberFormat(minValue) as unknown as string}
                    </span>
                    <span>-</span>
                    <span className="">
                      £{numberFormat(maxValue) as unknown as string}
                    </span>
                  </div>
                </div>
              </div>
              <div className="px-2">
                <div className="relative pt-1">
                  <label
                    htmlFor="customRange1"
                    className="form-label block text-base font-normal mb-2 text-primarycolor"
                    style={{
                      color:
                        getThemes && getThemes.color1 == "rgba(255,255,255,1)"
                          ? "rgba(180,180,180,1)"
                          : getThemes.color1,
                    }}
                  >
                    Bedrooms
                  </label>

                  <MultiRangeSlider
                    min={filterProperties?.minBedroom as unknown as string}
                    max={filterProperties?.maxBedroom as unknown as string}
                    step={1}
                    minValue={
                      filterProperties?.minBedroom !== undefined
                        ? filterProperties?.minBedroom
                        : minBedroom
                    }
                    maxValue={
                      filterProperties?.maxBedroom !== undefined
                        ? filterProperties?.maxBedroom
                        : maxBedroom
                    }
                    onInput={(e) => {
                      handleBedInput(e);
                    }}
                    ruler={false}
                    label={false}
                    barInnerColor={
                      getThemes && getThemes.color1 == "rgba(255,255,255,1)"
                        ? "rgba(180,180,180,1)"
                        : getThemes.color1
                    }
                    thumbLeftColor={getThemes && getThemes.color2}
                    thumbRightColor={getThemes && getThemes.color2}
                    className="ml-2"
                  />
                  <div className="flex justify-items-center justify-center gap-x-5">
                    <span className="">{minBedroom as unknown as string}</span>
                    <span>-</span>
                    <span className="">{maxBedroom as unknown as string}</span>
                  </div>
                </div>
              </div>

              <div className="px-2">
                <div className="relative pt-1">
                  <label
                    htmlFor="customRange1"
                    className="form-label block text-base font-normal mb-2 text-primarycolor"
                    style={{
                      color:
                        getThemes && getThemes.color1 == "rgba(255,255,255,1)"
                          ? "rgba(180,180,180,1)"
                          : getThemes.color1,
                    }}
                  >
                    Gross Yield
                  </label>
                  <MultiRangeSlider
                    min={filterProperties?.minGrossYield as unknown as string}
                    max={filterProperties?.maxGrossYield as unknown as string}
                    step={1}
                    minValue={
                      filterProperties?.minGrossYield !== undefined
                        ? filterProperties?.minGrossYield
                        : minYield
                    }
                    maxValue={
                      filterProperties?.maxGrossYield !== undefined
                        ? filterProperties?.maxGrossYield
                        : maxYield
                    }
                    onInput={(e) => {
                      handleYieldInput(e);
                    }}
                    ruler={false}
                    label={false}
                    barInnerColor={
                      getThemes && getThemes.color1 == "rgba(255,255,255,1)"
                        ? "rgba(180,180,180,1)"
                        : getThemes.color1
                    }
                    thumbLeftColor={getThemes && getThemes.color2}
                    thumbRightColor={getThemes && getThemes.color2}
                  />
                  <div className="flex justify-items-center justify-center gap-x-5">
                    <span className="">{minYield as unknown as string}%</span>
                    <span>-</span>
                    <span className="">{maxYield as unknown as string}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!loading && (
        <div className="flex flex-wrap justify-between m-auto max-w-7xl mb-5">
          <div className="flex flex-wrap justify-end  items-center gap-3">
            <div className="flex items-center">
              <input
                onChange={(e) => {
                  setIsReserved(e.target.checked);
                }}
                checked={isReserved}
                id="disabled-checked-checkbox"
                type="checkbox"
                value=""
                className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="disabled-checked-checkbox"
                className="ml-2 text-sm font-medium cursor-pointer text-gray-400 dark:text-gray-500"
              >
                Show Reserved
              </label>
            </div>
          </div>
          <div className="flex flex-wrap justify-end  items-center gap-3">
            <h3 className="text-gray-700 font-medium">Sort By : </h3>
            <div className="hs-dropdown relative inline-flex">
              <button
                id="hs-dropdown-default"
                type="button"
                className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-500 font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 transition-all text-sm"
              >
                {sortDate.replace("Date", "").replace("Latest", "Newest")} Date
                <svg
                  className="hs-dropdown-open:rotate-180 w-2.5 h-2.5 text-gray-600"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>

              <div
                className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms]  w-72 hidden z-10 mt-2 min-w-[15rem] bg-white shadow-md rounded-lg p-2 bg-gray-50 border border-gray-50 divide-gray-50"
                aria-labelledby="hs-dropdown-default"
              >
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortDateAction("By Date");
                  }}
                >
                  By Date
                </a>
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortDateAction("Latest");
                  }}
                >
                  Newest Date
                </a>
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortDateAction("Oldest");
                  }}
                >
                  Oldest Date
                </a>
              </div>
            </div>
            <div className="hs-dropdown relative inline-flex">
              <button
                id="hs-dropdown-default"
                type="button"
                className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-500 font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 transition-all text-sm"
              >
                {sortPrice.replace("Price", "")} Price
                <svg
                  className="hs-dropdown-open:rotate-180 w-2.5 h-2.5 text-gray-600"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>

              <div
                className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms]  w-72 hidden z-10 mt-2 min-w-[15rem] bg-white shadow-md rounded-lg p-2 bg-gray-50 border border-gray-50 divide-gray-50"
                aria-labelledby="hs-dropdown-default"
              >
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortPriceAction("By Price");
                  }}
                >
                  By Price
                </a>
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortPriceAction("Lowest");
                  }}
                >
                  Lowest Price
                </a>
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortPriceAction("Highest");
                  }}
                >
                  Highest Price
                </a>
              </div>
            </div>
            <div className="hs-dropdown relative inline-flex">
              <button
                id="hs-dropdown-default"
                type="button"
                className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-500 font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 transition-all text-sm"
              >
                {sortYield.replace("Yield", "")} Yield
                <svg
                  className="hs-dropdown-open:rotate-180 w-2.5 h-2.5 text-gray-600"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>

              <div
                className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms]  w-72 hidden z-10 mt-2 min-w-[15rem] bg-white shadow-md rounded-lg p-2 bg-gray-50 border border-gray-50 divide-gray-50"
                aria-labelledby="hs-dropdown-default"
              >
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortYieldAction("By Yield");
                  }}
                >
                  By Yield
                </a>
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortYieldAction("Lowest");
                  }}
                >
                  Lowest Yield
                </a>
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortYieldAction("Highest");
                  }}
                >
                  Highest Yield
                </a>
              </div>
            </div>
            <div className="hs-dropdown relative inline-flex">
              <button
                id="hs-dropdown-default"
                type="button"
                className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-500 font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 transition-all text-sm"
              >
                {sortDiscount.replace("Discount", "")} Discount
                <svg
                  className="hs-dropdown-open:rotate-180 w-2.5 h-2.5 text-gray-600"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>

              <div
                className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms]  w-72 hidden z-10 mt-2 min-w-[15rem] bg-white shadow-md rounded-lg p-2 bg-gray-50 border border-gray-50 divide-gray-50"
                aria-labelledby="hs-dropdown-default"
              >
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortDiscountAction("By Discount");
                  }}
                >
                  By Discount
                </a>
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortDiscountAction("Lowest");
                  }}
                >
                  Lowest Discount
                </a>
                <a
                  className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500  hover:text-gray-700"
                  href="#"
                  onClick={() => {
                    sortDiscountAction("Highest");
                  }}
                >
                  Highest Discount
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <<=================includes property listing=================>> */}

      <div className="max-w-7xl m-auto pb-10">
        {!loading && (
          <div
            style={{
              background: `${getThemes && getThemes.color2}`,
            }}
            className="rounded-xl"
          >
            <h3
              className="text-left ml-3 mt-4 text-md inline-block float-left px-5 py-1 text-white-f2f9fa rounded-xl"
              style={{
                background: `${getThemes && getThemes.color1}`,
                color: `${getThemes && getThemes.txtColor}`,
              }}
            >
              <FontAwesomeIcon icon={faStar} className="mr-1 text-sm" />{" "}
              Featured Properties
            </h3>
            <div className="grid lg:grid-cols-4 grid-cols-1 w-full m-auto gap-3 lg:px-4 px-3 pt-4 mb-5">
              {featuredProperties?.results.map((ele, index) => (
                <PropertyCardFeatured
                  key={index}
                  discount={ele.discountToMarket as unknown as string}
                  discountLabel={ele.discountLabel}
                  id={ele._id}
                  available={ele.reserved}
                  image={
                    !!ele.images
                      ? ele.images[0].url
                      : "https://betaapi.truevals.com/uploads/properties/Main_Image.jpeg"
                  }
                  streetName={ele.propertyOf}
                  price={ele.price as unknown as string}
                  bedrooms={ele.bedrooms as unknown as string}
                  value={ele.estimatedValue as unknown as string}
                  rent={ele.averageRent as unknown as string}
                  netCashFlow={ele.netCashflow as unknown as string}
                  grossYield={ele.rentGrossYield as unknown as string}
                  strategy={ele.strategy as unknown as string}
                  index={index}
                />
              ))}
            </div>
          </div>
        )}
        {!loadFilter && (
          <>
            <div className="grid lg:grid-cols-3 grid-cols-1 w-full m-auto gap-3 lg:px-0 px-3">
              {properties?.map((ele, index) => (
                <PropertyCard
                  key={index}
                  discount={ele.discountToMarket as unknown as string}
                  discountLabel={ele.discountLabel}
                  id={ele._id}
                  available={ele.reserved}
                  image={
                    !!ele.images
                      ? ele.images[0].url
                      : "https://betaapi.truevals.com/uploads/properties/Main_Image.jpeg"
                  }
                  streetName={ele.propertyOf}
                  price={ele.price as unknown as string}
                  bedrooms={ele.bedrooms as unknown as string}
                  value={ele.estimatedValue as unknown as string}
                  rent={ele.averageRent as unknown as string}
                  netCashFlow={ele.netCashflow as unknown as string}
                  grossYield={ele.rentGrossYield as unknown as string}
                  strategy={ele.strategy as unknown as string}
                />
              ))}
            </div>
            {properties?.length == 0 && (
              <div className="flex w-full justify-center items-center mt-10">
                <h1 className="text-center">No Properties Found</h1>
              </div>
            )}
          </>
        )}
        {loading && (
          <div className="h-screen flex top-[50%] bottom-0 m-auto justify-center items-center">
            <LoadingDiv
              color={
                getThemes && getThemes.color1 == "rgba(255,255,255,1)"
                  ? "rgba(180,180,180,1)"
                  : getThemes.color1
              }
            />
          </div>
        )}
        {loadFilter && (
          <div className="flex w-full justify-center items-center  mt-10">
            <LoadingDiv
              color={
                getThemes && getThemes.color1 == "rgba(255,255,255,1)"
                  ? "rgba(180,180,180,1)"
                  : getThemes.color1
              }
            />
          </div>
        )}

        {!loading && !loadFilter && (
          <div ref={viewMoreButtonRef}>
            {/* {!loadFilter && ( */}
              <div className="w-full justify-center items-center">
                <button className="">
                  {loadingMore && (
                    <LoadingDiv
                      color={
                        getThemes && getThemes.color1 == "rgba(255,255,255,1)"
                          ? "rgba(180,180,180,1)"
                          : getThemes.color1
                      }
                    />
                  )}
                </button>
              </div>
            {/* )} */}
          </div>
        )}
      </div>
    </div>
  );
}

export default PropertyListing;
