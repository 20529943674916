import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReviewStars from "../components/ReviewStars";
import Rightarrow from "../images/icons/arrow-right-solid.svg";
import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";

function Blog() {
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );
  return (
    <div
      className="Home pt-28 flex justify-center items-center h-[93.2vh]"
    >
      <h3 className="lg:text-3xl text-2xl font-semibold text-center leading-tight pb-6 justify-center items-center align-middle">
          We’re currently updating our blog – please bear with us.
          </h3>
    </div>
  );
}

export default Blog;
