import { Link } from "react-router-dom";
import {
  faPoundSign,
  faBed,
  faUsers,
  faChartLine,
  faMoneyBillAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTokenStore from "../../store/store";
import { numberFormat } from "../../utils/properties";
import useThemeStore from "../../store/themeStore";
import { ThemeProps } from "../../utils/theme";

interface PropertyCardProps {
  id: string;
  discount: string;
  available: Boolean;
  image: string;
  streetName: string;
  price: string;
  bedrooms: string;
  value: string;
  rent: string;
  netCashFlow: string;
  grossYield: string;
  strategy: string;
  discountLabel?: string;
  index:number
}

const PropertyCardFeatured = ({
  id,
  discount,
  available,
  image,
  streetName,
  price,
  bedrooms,
  value,
  rent,
  netCashFlow,
  grossYield,
  strategy,
  discountLabel,
  index
}: PropertyCardProps) => {
  const getToken = useTokenStore((state) => state.token);
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );
  return (
    <Link className="flex justify-between items-start flex-col bg-white-f2f9fa mb-5 rounded-xl overflow-hidden shadow-md hover:shadow-xl" to={getToken ? `/listing/${id}` : `/login`} target="_blank" rel="noopener noreferrer">
      <div className="max-w-7xl">
        <div className="grid ">
          <div className=" rounded-xl p-4 relative  hover:transition hover:duration-150 hover:ease-in-out ">
            {discountLabel && discountLabel != "" && (
              <div className="absolute text-center left-0 right-0 mt-7">
                <button
                  className="bg-gradient-to-br text-sm from-primarycolor to-secondarycolor py-1 w-36 m-auto top-4 rounded-md text-white-f2f9fa"
                  style={{
                    background: `linear-gradient(to bottom,${
                      getThemes && getThemes!.color1
                    },${getThemes && getThemes.color2})`,
                  }}
                >
                  {discountLabel}
                </button>
              </div>
            )}
            {!discountLabel && (
              <div className="absolute text-center left-0 right-0 mt-7">
                <button
                  className="bg-gradient-to-br text-sm from-primarycolor to-secondarycolor py-1 w-36 m-auto top-4 rounded-md text-white-f2f9fa"
                  style={{
                    background: `linear-gradient(to bottom,${
                      getThemes && getThemes!.color1
                    },${getThemes && getThemes.color2})`,
                  }}
                >
                  {discount}% Discount
                </button>
              </div>
            )}
            {discountLabel && discountLabel == "" && (
              <div className="absolute text-center left-0 right-0 mt-7">
                <button
                  className="bg-gradient-to-br text-sm from-primarycolor to-secondarycolor py-1 w-36 m-auto top-4 rounded-md text-white-f2f9fa"
                  style={{
                    background: `linear-gradient(to bottom,${
                      getThemes && getThemes!.color1
                    },${getThemes && getThemes.color2})`,
                  }}
                >
                  {discount}% Discount
                </button>
              </div>
            )}
            <div className="w-full">
              <img className="rounded-xl lg:h-[200px] w-full" src={image} alt="" />
            </div>
            <div className="absolute text-center left-0 right-0 -mt-5">
              <button
                className="bg-gradient-to-br text-sm from-primarycolor to-secondarycolor py-2 w-36 m-auto top-4 rounded-md text-white-f2f9fa"
                style={{
                  background: `linear-gradient(to top,${
                    getThemes && getThemes.color1
                  },${getThemes && getThemes.color2})`,
                }}
              >
                {!available ? "Available" : "Reserved"}
              </button>
            </div>
            <div className="pt-7">
              <h3 className="font-roboto text-lg font-medium text-md hover:underline hover:text-primarycolor cursor-pointer">
                {streetName}
              </h3>
              <div className="text-left text-xs font-roboto font-medium pt-3 text-black-373933">
                <ul className="grid grid-cols-2">
                  <li className="border-b py-2 gap-2 flex items-center">
                    <FontAwesomeIcon icon={faPoundSign} className="" />
                    Price £{numberFormat(price as unknown as number)}
                  </li>
                  <li className="border-b py-2 gap-2 flex items-center">
                    <FontAwesomeIcon icon={faBed} />
                    {bedrooms}
                  </li>
                  <li className="border-b py-2 gap-2 flex items-center">
                    <FontAwesomeIcon icon={faPoundSign} className="" />
                    Zoopla Valuation £{numberFormat(value as unknown as number)}
                  </li>
                  <li className="border-b py-2 gap-2 flex items-center">
                    <FontAwesomeIcon icon={faUsers} className="" />
                    Discount {discount}%
                  </li>
                  <li className="border-b py-2 gap-2 flex items-center">
                    <FontAwesomeIcon icon={faUsers} className="" />
                    Rent £{numberFormat(rent as unknown as number)}
                  </li>

                  <li className="border-b py-2 gap-2 flex items-center">
                    <FontAwesomeIcon icon={faChartLine} className="" />
                    Net Cashflow £
                    {numberFormat(netCashFlow as unknown as number)}
                  </li>
                  <li className="py-2 gap-2 flex items-center">
                    <FontAwesomeIcon icon={faChartLine} className="" />
                    Yield {grossYield}%
                  </li>

                  {strategy != "" && (
                    <li className="py-2 gap-2 flex items-center font-bold">
                      <FontAwesomeIcon icon={faMoneyBillAlt} className="" />
                      {strategy}
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-red-be1e2e w-full py-2 text-white-f2f9fa text-sm" style={{
              background: `${getThemes && getThemes.color1}`,
            }}>
       {index == 0 && 'Top BTL'}
       {index == 1 && 'Top HMO'}
       {index == 2 && 'Top Discount'}
       {index == 3 && 'Top Apartment Block'}
      </div>
    </Link>
  );
};

export default PropertyCardFeatured;
