import axios from "axios";
import { baseUrl } from ".";

export interface RegisterProps {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
}

export interface VerifyProps {
  email: string;
  otp: Number;
}

export interface VerifyForgetProps {
  userHandle: string;
  otp: string;
  password: string;
}

export interface LoginProps {
  email: string;
  password: string;
}

export interface ResultMessageProps {
  message: string;
  status: Number;
}

export interface ResultLoginProps {
  message: string;
  token: string;
  user: {
    id: string;
    fullname: string;
    email: string;
    userHandle: string;
  };
  status: Number;
}

export const registerUser = async (
  data: RegisterProps
): Promise<ResultMessageProps> => {
  try {
    const response = await axios.post(
      baseUrl + "/whitelabel/registerClientNew",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );

    return { status: 201, message: response.data.message };
  } catch (error: any) {
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
};

export const verifyOTP = async (
  data: VerifyProps
): Promise<ResultMessageProps> => {
  try {
    const response = await axios.post(baseUrl + "/whitelabel/verifyOTP", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
    });
    return { status: 200, message: response.data.message };
  } catch (error: any) {
    return { status: 400, message: error.response.data.message };
  }
};

export const loginUser = async (
  data: LoginProps
): Promise<ResultLoginProps> => {
  try {
    const response = await axios.post(baseUrl + "/whitelabel/loginNew", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
    });
    return { status: 200, ...response.data };
  } catch (error: any) {
    return {
      status: error.response.status,
      message: error.response.data.message,
      token: "",
      user: {
        id: "",
        fullname: "",
        email: "",
        userHandle: "",
      },
    };
  }
};

export const forgetPassword = async (
  email: string
): Promise<ResultLoginProps> => {
  try {
    const response = await axios.post(
      baseUrl + "/whitelabel/forgetPasswordNew",
      { email: email },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    return { status: 200, ...response.data };
  } catch (error: any) {
    console.log(error);
    return {
      status: 400,
      message: error.response.data.message,
      token: "",
      user: {
        id: "",
        fullname: "",
        email: "",
        userHandle: "",
      },
    };
  }
};

export const forgetPasswordOTP = async (
  data: VerifyForgetProps
): Promise<ResultLoginProps> => {
  try {
    const response = await axios.put(
      baseUrl + "/whitelabel/setNewPasswordNew",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      }
    );
    return { status: 200, ...response.data };
  } catch (error: any) {
    console.log(error);
    return {
      status: 400,
      message: error.response.data.message,
      token: "",
      user: {
        id: "",
        fullname: "",
        email: "",
        userHandle: "",
      },
    };
  }
};
