import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { registerUser, ResultMessageProps } from "../utils/user";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";
import useTokenStore from "../store/store";

type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword?: string;
};

function Userregister() {
  const [loading, setLoading] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [message, setMessage] = useState<ResultMessageProps>({
    message: "",
    status: 0,
  });
  const navigate = useNavigate();
  const formSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required")
      .min(3, "First Name must be at 3 char long"),
    lastName: Yup.string()
      .required("Last Name is required")
      .min(3, "Last Name must be at 3 char long"),
    email: Yup.string()
      .required("Email is required")
      .min(3, "Email must be at 3 char long")
      .email("Email is invalid"),
    phone: Yup.number()
      .required("Phone is required")
      .min(10, "Phone must be at 10 char long"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at 8 char long"),
    confirmPassword: Yup.string()
      .required("Password is required")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>(formOptions);

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    setLoading(true);
    setFormSubmit(true);
    delete data.confirmPassword;
    const response = await registerUser(data);
    setMessage(response);
    setLoading(false);
    setTimeout(() => {
      if (response.status === 201) {
        navigate("/login");
      }
      if (response.status === 412) {
        navigate("/login");
      }
    }, 3000);
  };

  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  const getToken = useTokenStore((state) => state.token);
  useEffect(() => {
    if (getToken) {
      navigate("/listing");
    }
  }, []);

  return (
    <div className="userregister">
      <Helmet>
        <title>Register | {getThemes && getThemes.name}</title>
      </Helmet>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-primarycolor w-full max-h-full lg:h-[90vh] h-fit m-auto justify-center flex"
        style={{ background: getThemes && getThemes.color1 }}
      >
        <div className="lg:w-2/5 w-full px-5 pt-28">
          <p className="text-3xl font-roboto font-medium text-white-f2f9fa pb-8">
            Register
          </p>
          <div className="w-full py-2">
            <div className="grid grid-cols-2 gap-4 py-2">
              <div className="">
                <label
                  htmlFor="input-label-with-helper-text"
                  className="block text-base text-white-f2f9fa font-medium mb-2 text-left"
                >
                  First Name *
                </label>
                <input
                  type="text"
                  id="input-label-with-helper-text"
                  className="py-3 px-4 block w-full text-gray-600 rounded-md text-sm"
                  placeholder="First Name"
                  aria-describedby="hs-input-helper-text"
                  {...register("firstName", { required: true })}
                />

                <small className="flex justify-start pt-3 text-red-hover-ce312b">
                  {errors.firstName && <span>First Name is required</span>}
                </small>
              </div>
              <div className="">
                <label
                  htmlFor="input-label-with-helper-text"
                  className="block text-base text-white-f2f9fa font-medium mb-2 text-left"
                >
                  Last Name *
                </label>
                <input
                  type="text"
                  id="input-label-with-helper-text"
                  className="py-3 px-4 block w-full text-gray-600 rounded-md text-sm"
                  placeholder="Last Name"
                  aria-describedby="hs-input-helper-text"
                  {...register("lastName", { required: true })}
                />

                <small className="flex justify-start pt-3 text-red-hover-ce312b">
                  {errors.lastName && <span>Last Name is required</span>}
                </small>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 py-2">
              <div className="">
                <label
                  htmlFor="input-label-with-helper-text"
                  className="block text-base text-white-f2f9fa font-medium mb-2 text-left"
                >
                  Phone Number *
                </label>
                <input
                  type="tel"
                  id="input-label-with-helper-text"
                  className="py-3 px-4 block w-full text-gray-600 rounded-md text-sm"
                  placeholder="Phone Number"
                  aria-describedby="hs-input-helper-text"
                  {...register("phone", { required: true })}
                />

                <small className="flex justify-start pt-3 text-red-hover-ce312b">
                  {errors.phone && <span>Phone Number is required</span>}
                </small>
              </div>
              <div className="">
                <label
                  htmlFor="input-label-with-helper-text"
                  className="block text-base text-white-f2f9fa font-medium mb-2 text-left"
                >
                  E-mail *
                </label>
                <input
                  type="email"
                  id="input-label-with-helper-text"
                  className="py-3 px-4 block w-full text-gray-600 rounded-md text-sm"
                  placeholder="Email Address  "
                  aria-describedby="hs-input-helper-text"
                  {...register("email", { required: true })}
                />

                <small className="flex justify-start pt-3 text-red-hover-ce312b">
                  {errors.email && <span>Email Address is required</span>}
                </small>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 py-2">
              <div className="">
                <label
                  htmlFor="input-label-with-helper-text"
                  className="block text-base text-white-f2f9fa font-medium mb-2 text-left"
                >
                  Password *
                </label>
                <input
                  type="password"
                  id="input-label-with-helper-text"
                  className="py-3 px-4 block w-full text-gray-600 rounded-md text-sm"
                  placeholder="Password"
                  aria-describedby="hs-input-helper-text"
                  {...register("password", { required: true })}
                />

                <small className="flex justify-start pt-3 text-red-hover-ce312b">
                  {errors.password && <span>Password is required</span>}
                </small>
              </div>
              <div className="">
                <label
                  htmlFor="input-label-with-helper-text"
                  className="block text-base text-white-f2f9fa font-medium mb-2 text-left"
                >
                  Confirm Password *
                </label>
                <input
                  type="password"
                  id="input-label-with-helper-text"
                  className="py-3 px-4 block w-full text-gray-600 rounded-md text-sm"
                  placeholder="Confirm Password"
                  aria-describedby="hs-input-helper-text"
                  {...register("confirmPassword", { required: true })}
                />

                <small className="flex justify-start pt-3 text-red-hover-ce312b">
                  {errors.confirmPassword && (
                    <span>{errors.confirmPassword.message}</span>
                  )}
                </small>
              </div>
            </div>
          </div>

          {message.status === 0 && (
            <div className="mt-4">
              <button
                type="submit"
                className="w-full px-4 inline-flex justify-center items-center gap-2 rounded-md text-primarycolor font-semibold bg-white-f2f9fa hover:bg-gray-200 py-3"
              >
                Sign Up
                {/* <=====When loading show spinner=====> */}
                {loading && (
                  <span
                    className="animate-spin border-btnscolor inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                    role="status"
                    aria-label="loading"
                  ></span>
                )}
              </button>

              <Link to="/login">
                <button
                  type="button"
                  className="w-full inline-flex justify-center gap-2 text-white-f2f9fa font-medium tracking-wide hover:underline py-2"
                >
                  or if you are already a member, you can log in.
                </button>
              </Link>
            </div>
          )}

          {formSubmit && (
            <p className="w-full inline-flex justify-center gap-2 text-white-f2f9fa font-normal tracking-wide py-2">
              Registration submitted, please check your emails to complete
              registration. Sometimes, not always, it ends in your junk mail.
            </p>
          )}
        </div>
      </form>

      <div className="absolute bottom-[50%] left-1/2 -translate-x-1/2">
        <div
          id="dismiss-toast"
          className={`hs-removing:translate-x-5 ${
            message.status === 0 ? "hidden" : "visible"
          } hs-removing:opacity-0 transition duration-300 max-w-xs ${
            message.status === 201 ? "bg-grey-848484" : "bg-red-be1e2e"
          } rounded-md shadow-lg`}
          role="alert"
        >
          <div className="flex  p-4 h-[150px] w-[250px] flex-col justify-center items-center">
            <p className="text-sm text-white-f2f9fa pr-2">{message.message}</p>
            <div className="mt-4">
              {message.status !== 200 && (
                <button
                  type="button"
                  className="inline-flex flex-shrink-0 justify-center items-center h-4 w-4 p-4 px-10 text-white-f2f9fa border-2"
                  data-hs-remove-element="#dismiss-toast"
                  onClick={() => {
                    setMessage({ message: "", status: 0 });
                  }}
                >
                  OK
                </button>
              )}
            </div>
            <div className="ml-auto"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Userregister;
