import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import useTokenStore from "../store/store";
import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";
import { forgetPassword, ResultMessageProps } from "../utils/user";

type Inputs = {
  email: string;
};

function Resetpwd() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<ResultMessageProps>({
    message: "",
    status: 0,
  });

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    setLoading(true);
    const response = await forgetPassword(data.email);
    setMessage(response);
    setLoading(false);
    setTimeout(() => {
      setMessage({ message: "", status: 0 });
      if (response.status === 200) {
        navigate("/login");
      }
    }, 5000);
  };

  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  const getToken = useTokenStore((state) => state.token);
  useEffect(() => {
    if (getToken) {
      navigate("/listing");
    }
  }, []);

  return (
    <div className="resetpwd">
      <Helmet>
        <title>Forget Password | {getThemes && getThemes.name}</title>
      </Helmet>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-primarycolor w-full max-h-full h-[80vh] m-auto justify-center flex"
        style={{ background: getThemes && getThemes.color1 }}
      >
        <div className="lg:w-1/5 w-full px-5 pt-28">
          <p className="text-3xl font-roboto font-medium text-white-f2f9fa pb-8">
            Forget Password
          </p>
          <div className="w-full py-2">
            <label
              htmlFor="input-label-with-helper-text"
              className="block text-base text-white-f2f9fa font-medium mb-2 text-left"
            >
              Email Address
            </label>
            <input
              type="email"
              id="input-label-with-helper-text"
              className="py-3 px-4 block w-full text-gray-600 rounded-md text-sm"
              placeholder="Email Address  "
              aria-describedby="hs-input-helper-text"
              {...register("email", { required: true })}
            />

            <small className="flex justify-start pt-3 text-red-hover-ce312b">
              {errors.email && <span>Email Address is required</span>}
            </small>
          </div>

          <div className="">
            <button
              type="submit"
              className="w-full mt-3 px-4 inline-flex justify-center items-center gap-2 rounded-md text-primarycolor font-semibold bg-white-f2f9fa hover:bg-gray-200 py-3"
            >
              Submit
              {/* <=====When loading show spinner=====> */}
              {loading && (
                <span
                  className="animate-spin border-btnscolor inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                  role="status"
                  aria-label="loading"
                ></span>
              )}
            </button>

            <Link to="/register">
              <button
                type="button"
                className="w-full inline-flex justify-center gap-2 text-white-f2f9fa font-medium tracking-wide hover:underline py-2"
              >
                Don't have an account? Signup
              </button>
            </Link>
          </div>
        </div>
      </form>
      <div className="absolute bottom-2 left-1/2 -translate-x-1/2">
        <div
          id="dismiss-toast"
          className={`hs-removing:translate-x-5 ${
            message.status === 0 ? "hidden" : "visible"
          } hs-removing:opacity-0 transition duration-300 max-w-xs ${
            message.status === 200 ? "bg-grey-848484" : "bg-red-be1e2e"
          } rounded-md shadow-lg`}
          role="alert"
        >
          <div className="flex p-4">
            <p className="text-sm text-white-f2f9fa pr-2">{message.message}</p>

            <div className="ml-auto"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resetpwd;
