import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Facebook from "../../images/social_icons/facebook-f.svg";
import Instagram from "../../images/social_icons/instagram.svg";
import Twitter from "../../images/social_icons/twitter.svg";
import Linkedin from "../../images/social_icons/linkedin.svg";
import Youtube from "../../images/social_icons/youtube.svg";
import useThemeStore from "../../store/themeStore";
import { ThemeProps } from "../../utils/theme";
import GenInTouch from "../GenInTouch";

const Footer = () => {
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );
  return (
    <>
      {getThemes && getThemes.website === "https://trustbricks.co.uk" && (
        <footer
          className="w-full py-10 px-4 sm:px-6 lg:px-8 mx-auto"
          style={{ background: getThemes && getThemes.color1 }}
        >
          {/* <!-- Grid --> */}
          <div className="max-w-7xl m-auto grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6 mb-10">
            <div className="col-span-full hidden lg:col-span-1 lg:block text-left">
              <a
                className="flex-none text-xl font-semibold dark:text-white"
                href="#"
                aria-label="Trustbricks"
              >
                <img
                  src={getThemes && getThemes!.logo}
                  className="w-48"
                  alt=""
                />
              </a>
              <p className="mt-3 text-xs sm:text-sm text-white-f2f9fa">
                Unit 6, Church Farm, Church Road, Suffolk<br></br>
                United Kingdom, IP29 5AX
              </p>
            </div>
            {/* <!-- End Col --> */}

            <div className="text-left text-white-f2f9fa">
              <h4 className="text-lg font-medium text-white-f2f9fa uppercase">
                Who’s it for
              </h4>

              <div className="mt-3 grid space-y-3 text-sm">
                <p>
                  <Link
                    className="inline-flex gap-x-2 text-white-f2f9fa"
                    to="https://trustbricks.co.uk/first-time-property-investors/"
                  >
                    New Investors
                  </Link>
                </p>
                <p>
                  <Link
                    className="inline-flex gap-x-2 text-white-f2f9fa"
                    to="https://trustbricks.co.uk/invest-in-property/"
                  >
                    Portfolio Investors
                  </Link>
                </p>
                <p>
                  <Link
                    className="inline-flex gap-x-2 text-white-f2f9fa"
                    to="https://trustbricks.co.uk/refer-an-investor/"
                  >
                    Refer an Investor
                  </Link>
                </p>
                <p>
                  <Link
                    className="inline-flex gap-x-2 text-white-f2f9fa"
                    to="https://trustbricks.co.uk/white-label/"
                  >
                    White Label Solution
                  </Link>
                </p>
              </div>
            </div>
            {/* <!-- End Col --> */}

            <div className="text-left text-white-f2f9fa">
              <h4 className="text-lg font-medium text-white-f2f9fa uppercase">
                Resources
              </h4>

              <div className="mt-3 grid space-y-3 text-sm">
                <p>
                  <Link
                    className="inline-flex gap-x-2 text-white-f2f9fa"
                    to="https://trustbricks.co.uk/#howitworks"
                  >
                    How It Works
                  </Link>
                </p>
                <p>
                  <Link
                    className="inline-flex gap-x-2 text-white-f2f9fa"
                    to="https://www.youtube.com/channel/UCtbHPeoFa4tQaWSJLrOsrcQ"
                  >
                    Videos
                  </Link>
                </p>

                <p>
                  <Link
                    className="inline-flex gap-x-2 text-white-f2f9fa"
                    to="https://trustbricks.co.uk/blog/"
                  >
                    Blog
                  </Link>
                </p>
              </div>
            </div>
            {/* <!-- End Col --> */}

            <div className="text-left text-white-f2f9fa">
              <h4 className="text-lg font-medium text-white-f2f9fa uppercase">
                Contact Us
              </h4>

              <div className="mt-3 grid space-y-3 text-sm">
                <p>
                  <Link
                    className="inline-flex gap-x-2 text-white-f2f9fa"
                    to="https://trustbricks.co.uk#elementor-action%3Aaction%3Dpopup%3Aopen%26settings%3DeyJpZCI6IjE0MDQiLCJ0b2dnbGUiOmZhbHNlfQ%3D%3D"
                  >
                    Arrange a call
                  </Link>
                </p>
                <p>
                  <Link
                    className="inline-flex gap-x-2 text-white-f2f9fa"
                    to="mailto:contact@https://trustbricks.co.uk"
                  >
                    Email us
                  </Link>
                </p>
              </div>
            </div>
            {/* <!-- End Col --> */}

            <div className="text-left text-white-f2f9fa">
              <h4 className="text-lg font-medium text-white-f2f9fa uppercase">
                Stay Connected
              </h4>

              <div className="my-5 gap-5 flex text-sm">
                <Link
                  className="flex items-center sm:py-2"
                  to="https://www.facebook.com/trustbricks"
                >
                  <img src={Facebook} className="w-6 h-6" />
                </Link>
                <Link
                  className="flex items-center sm:py-2"
                  to="https://www.instagram.com/trust_bricks/"
                >
                  <img src={Instagram} className="w-6 h-6" />
                </Link>
                <Link
                  className="flex items-center sm:py-2"
                  to="https://twitter.com/trustbricks/"
                >
                  <img src={Twitter} className="w-6 h-6" />
                </Link>
                <Link
                  className="flex items-center sm:py-2"
                  to="https://www.linkedin.com/company/trustbricks/"
                >
                  <img src={Linkedin} className="w-6 h-6" />
                </Link>
                <Link
                  className="flex items-center sm:py-2"
                  to="https://www.youtube.com/channel/UCtbHPeoFa4tQaWSJLrOsrcQ/"
                >
                  <img src={Youtube} className="w-6 h-6" />
                </Link>
              </div>
              <img
                src="https://trustbricks.co.uk/wp-content/uploads/2022/08/PRS_Logo_v2.png"
                alt=""
                className="w-2/3 py-2"
              />
            </div>
            {/* <!-- End Col --> */}
          </div>
          {/* <!-- End Grid --> */}

          <div className="max-w-7xl m-auto pt-5 mt-5">
            <div className="flex flex-row justify-evenly w-full">
              <div className="space-x-4 text-sm lg:w-1/2 sm:w-full flex">
                <p className="text-white-f2f9fa text-left w-full">
                  Copyright © 2023
                </p>
              </div>
              <div className="space-x-4 text-sm ml-4 lg:w-1/2 sm:w-full flex">
                <p className="text-white-f2f9fa w-full text-right">
                  <Link
                    className=""
                    to="https://trustbricks.co.uk/privacy-policy/"
                  >
                    Privacy policy
                  </Link>
                  <span className="px-1">|</span>
                  <Link
                    className=""
                    to="https://trustbricks.co.uk/legal-notice/"
                  >
                    Legal notice
                  </Link>
                </p>
              </div>

              {/* <!-- End Col --> */}
            </div>
          </div>
        </footer>
      )}
      {getThemes && getThemes.website !== "https://trustbricks.co.uk" && (
        <footer
          className="w-full py-5 px-4 sm:px-6 lg:px-8 mx-auto justify-center flex"
          style={{ background: getThemes && getThemes.color1 }}
        >
          <div>
            <p className="text-white-f2f9fa text-left w-full">
              Copyright © 2023
            </p>
          </div>
        </footer>
      )}
      <GenInTouch />
    </>
  );
};

export default Footer;
