import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import useThemeStore from "../store/themeStore";
import { HubspotProps, hubSpotContact } from "../utils/properties";
import { ThemeProps } from "../utils/theme";

const GenInTouch = () => {
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  const [message, setMessage] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<HubspotProps>();

  const onSubmit: SubmitHandler<HubspotProps> = async (data: HubspotProps) => {
    setLoading(true);
    const response = await hubSpotContact(data);
    setFirstname("");
    setLastname("");
    setEmail("");
    setPhone("");
    setDescription("");
    setMessage(response.message);
    setLoading(false);
    reset();
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  const [loading, setLoading] = useState(false);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  return (
    <>
      <div
        id="hs-large-modal"
        className={`hs-overlay hidden w-full h-full fixed top-0 left-0 z-[70] overflow-x-hidden overflow-y-auto`}
      >
        <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all lg:max-w-7xl lg:w-full m-3 lg:mx-auto">
          <div
            className="flex flex-col shadow-sm rounded-xl bg-gradient-to-r from-secondarycolor to-primarycolor"
            style={{
              background: `linear-gradient(to bottom,${
                getThemes && getThemes!.color1
              },${getThemes && getThemes.color2})`,
            }}
          >
            <div className="flex justify-start items-center py-3 px-4">
              <button
                type="button"
                className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-white-f2f9fa focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm"
                data-hs-overlay="#hs-large-modal"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="w-3.5 h-3.5"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
            <div className="overflow-y-auto pb-5">
              <div className="flex flex-wrap font-poppins text-left text-white-f2f9fa p-10 font-light">
                <div className="lg:w-1/2 w-full lg:pr-2 pr-0">
                  <h5 className="text-xl">
                    Find your next buy-to-let property
                  </h5>
                  <h3 className="text-6xl py-4">Get in touch</h3>
                  <h4 className="text-base tracking-wide">
                    If you'd like to request information about any of the
                    properties available on our site, or would like to
                    understand how you can easily secure your next investment
                    property, please contact us via this form.
                  </h4>
                </div>
                <div className="lg:w-1/2 w-full lg:mt-o mt-5">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-wrap"
                  >
                    <div className="w-1/2 pr-1">
                      <input
                        value={firstname}
                        onInput={(e) => setFirstname(e.currentTarget.value)}
                        type="text"
                        className="py-3 mb-2 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-900 text-gray-400"
                        placeholder="First Name"
                        {...register("firstname", { required: true })}
                      />
                    </div>
                    <div className="w-1/2 pl-1">
                      <input
                        value={lastname}
                        onInput={(e) => setLastname(e.currentTarget.value)}
                        type="text"
                        className="py-3  mb-2 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-900 text-gray-400"
                        placeholder="Last Name"
                        {...register("lastname", { required: true })}
                      />
                    </div>
                    <div className="w-1/2 pr-1">
                      <input
                        onInput={(e) => setEmail(e.currentTarget.value)}
                        value={email}
                        type="email"
                        className="py-3 mb-2 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-900 text-gray-400"
                        placeholder="Email"
                        {...register("email", { required: true })}
                      />
                    </div>
                    <div className="w-1/2 pl-1">
                      <input
                        onInput={(e) => setPhone(e.currentTarget.value)}
                        value={phone}
                        type="tel"
                        className="py-3 mb-2 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-900 text-gray-400"
                        placeholder="Phone"
                        {...register("mobilephone", { required: true })}
                      />
                    </div>
                    <textarea
                      className="py-3 mb-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-900  text-gray-400"
                      rows={3}
                      onInput={(e) => setDescription(e.currentTarget.value)}
                      value={description}
                      placeholder="Message"
                      {...register("message", { required: true })}
                    ></textarea>

                    <button
                      type="submit"
                      className="py-2.5 w-full rounded-full bg-secondarycolor hover:bg-white-f2f9fa text-center"
                      style={{ background: getThemes && getThemes.color1,color:getThemes && getThemes.txtColor }}
                    >
                      Send
                      {/* <=====When loading show spinner=====> */}
                      {loading && (
                        <span
                          className="animate-spin border-btnscolor  inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                          role="status"
                          aria-label="loading"
                        ></span>
                      )}
                    </button>
                    <div>{message}</div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenInTouch;
