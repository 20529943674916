import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import useTokenStore from "../store/store";
import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";
import { loginUser, ResultLoginProps } from "../utils/user";

type Inputs = {
  email: string;
  password: string;
};

function Userlogin() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<ResultLoginProps>({
    message: "",
    status: 0,
    token: "",
    user: {
      id: "",
      fullname: "",
      email: "",
      userHandle: "",
    },
  });

  const setToken = useTokenStore((state) => state.setToken);
  const navigate = useNavigate();
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  useEffect(() => {
    localStorage.removeItem("email");
  }, []);

  const onSubmit: SubmitHandler<Inputs> = async (data:Inputs) => {
    setLoading(true);
    const response = await loginUser(data);

    setMessage(response);
    setLoading(false);
    setTimeout(() => {
      if (response.status === 200) {
        setToken(response);
        navigate("/listing");
      }
      if (response.status === 412) {
        navigate("/login");
      }
    }, 3000);
  };

  const getToken = useTokenStore((state) => state.token);
  useEffect(() => {
    if (getToken) {
      navigate("/listing");
    }
  }, []);


  return (
    <div className="userlogin">
      <Helmet>
        <title>Login | {getThemes && getThemes.name}</title>
      </Helmet>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-primarycolor m-auto flex flex-wrap justify-center pt-44 pb-36" style={{background:getThemes && getThemes.color1}}
      >
        <div className="lg:w-4/12 w-full px-5">
          <p className="text-3xl font-roboto font-medium text-white-f2f9fa pb-8">
            Login
          </p>
          <div className="w-full py-2">
            <label
              htmlFor="input-label-with-helper-text"
              className="block text-base text-white-f2f9fa font-medium mb-2 text-left"
            >
              Email Address
            </label>
            <input
              type="email"
              id="input-label-with-helper-text"
              className="py-3 px-4 block w-full text-gray-600 rounded-md text-sm"
              placeholder="Email Address              "
              aria-describedby="hs-input-helper-text"
              {...register("email", { required: true })}
            />

            <small className="flex justify-start pt-3 text-red-hover-ce312b">
              {errors.email && <span>Email Address is required</span>}
            </small>
          </div>
          <div className="w-full py-2">
            <label
              htmlFor="input-label-with-helper-text"
              className="block text-base text-white-f2f9fa font-medium mb-2 text-left"
            >
              Password
            </label>
            <input
              type="password"
              id="input-label-with-helper-text"
              className="py-3 px-4 block w-full border-gray-500 text-gray-600 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="Password"
              aria-describedby="hs-input-helper-text"
              {...register("password", { required: true })}
            />

            <small className="flex justify-start pt-3 text-red-hover-ce312b">
              {errors.password && <span>Password is required</span>}
            </small>
          </div>
          {message.status === 0 && (
            <div className="">
              <button
                type="submit"
                className="w-full px-4 inline-flex justify-center items-center gap-2 rounded-md text-primarycolor font-semibold bg-white-f2f9fa hover:bg-gray-200 py-3"
              >
                Log In
                {/* <=====When loading show spinner=====> */}
                {loading && (
                  <span
                    className="animate-spin border-btnscolor  inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                    role="status"
                    aria-label="loading"
                  ></span>
                )}
              </button>

              <Link
                to={"/resetPassword"}
                
                className="w-full inline-flex justify-center gap-2 text-white-f2f9fa font-medium tracking-wide hover:underline py-2"
              >
                Lost your password?
              </Link>

              <Link to="/register">
                <button
                  type="button"
                  className="w-full inline-flex justify-center gap-2 text-white-f2f9fa font-medium tracking-wide hover:underline py-2"
                >
                  Dont have an account? Sign up
                </button>
              </Link>
            </div>
          )}
        </div>
      </form>
      <div className="absolute bottom-[50%] left-1/2 -translate-x-1/2">
        <div
          id="dismiss-toast"
          className={`hs-removing:translate-x-5 ${
            message.status === 0 ? "hidden" : "visible"
          } hs-removing:opacity-0 transition duration-300 max-w-xs ${
            message.status === 200 ? "bg-grey-848484" : "bg-red-be1e2e"
          } rounded-md shadow-lg`}
          role="alert"
        >
          <div className="flex p-4 h-[150px] w-[250px] flex-col justify-center items-center">
            <p className="text-sm text-white-f2f9fa pr-2">{message.message}</p>
            <div className="mt-4">
              {message.status !== 200 && (
                <button
                  type="button"
                  className="inline-flex flex-shrink-0 justify-center items-center h-4 w-4 p-4 px-10 text-white-f2f9fa border-2"
                  data-hs-remove-element="#dismiss-toast"
                  onClick={() => {
                    setMessage({
                      message: "",
                      status: 0,
                      token: "",
                      user: {
                        id: "",
                        fullname: "",
                        email: "",
                        userHandle: "",
                      },
                    });
                  }}
                >
                  OK
                </button>
              )}
            </div>

            <div className="ml-auto"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Userlogin;
