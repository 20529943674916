// import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useTokenStore from "../../store/store";
import Openmenu from "../../images/icons/bars-solid.svg";
import Closemenu from "../../images/icons/xmark-solid.svg";
import { ThemeProps } from "../../utils/theme";
import useThemeStore from "../../store/themeStore";
import { Helmet } from "react-helmet";

const Header = () => {
  const getToken = useTokenStore((state) => state.token);
  const logout = useTokenStore((state) => state.removeToken);
  const navigate = useNavigate();

  const logoutAction = () => {
    logout();
    navigate("/login");
  };

  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  return (
    <header
      className="fixed flex flex-wrap sm:justify-start sm:flex-nowrap z-[9999] w-full bg-white text-sm py-4 "
      style={{ background: getThemes && getThemes!.color1 }}
    >
      <Helmet>
        <title>{getThemes && getThemes.name}</title>
        <link rel="shortcut icon" href={getThemes && getThemes.favIcon} />
        {getThemes && getThemes.googleAnalytics ? (
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${getThemes.googleAnalytics}`}
          />
        ) : null}
        {getThemes && getThemes.googleAnalytics ? (
          <script>
            {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('config', '${getThemes.googleAnalytics}');
      `}
          </script>
        ) : null}
      
      </Helmet>

      <nav
        className="max-w-7xl w-full mx-auto lg:px-0 px-5 sm:flex sm:items-center sm:justify-between"
        aria-label="Global"
      >
        <div className="flex items-center justify-between">
          <Link
            className="flex-none text-xl font-semibold"
            to={getThemes && getThemes!.subwebsite}
          >
            <img
              src={getThemes && getThemes!.logo}
              className="lg:w-42 w-60 lg:h-16 h-24  top-0 object-contain object-left"
              alt=""
            />
          </Link>
          <div className="sm:hidden noprint">
            <button
              type="button"
              className="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 font-medium text-white-f2f9fa shadow-sm align-middle transition-all text-sm"
              data-hs-collapse="#navbar-with-mega-menu"
              aria-controls="navbar-with-mega-menu"
              aria-label="Toggle navigation"
              style={{ color: getThemes && getThemes!.txtColor }}
            >
              <img
                src={Openmenu}
                className="hs-collapse-open:hidden w-6 h-6"
                alt=""
              />
              <img
                src={Closemenu}
                className="hs-collapse-open:block hidden w-6 h-6"
                alt=""
              />
            </button>
          </div>
        </div>
        <div
          id="navbar-with-mega-menu"
          className="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:block noprint"
        >
          <div className="flex flex-col gap-5 mt-5 sm:flex-row sm:items-center sm:justify-end sm:mt-0 sm:pl-5">
            <a
              className="font-medium text-left text-white-f2f9fa"
              href={getThemes && getThemes.subwebsite}
              style={{ color: getThemes && getThemes!.txtColor }}
            >
              Home
            </a>
            {getThemes && getThemes.website === "https://trustbricks.co.uk" && (
              <div className="hs-dropdown [--strategy:static] sm:[--strategy:fixed] lg:[--trigger:hover] sm:[--trigger:click] [--adaptive:none]">
                <button
                  id="hs-mega-menu-basic-dr"
                  type="button"
                  className="flex items-center w-full text-white-f2f9fa font-medium"
                  style={{ color: getThemes && getThemes!.txtColor }}
                >
                  Who's it for
                  <svg
                    className="ml-2 w-2.5 h-2.5 text-white-f2f9fa"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    ></path>
                  </svg>
                </button>

                <div
                  className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 lg:border opacity-0 sm:w-48 z-10 bg-primarycolor sm:shadow-md rounded-lg p-2 before:absolute top-full before:-top-5 before:left-0 before:w-full before:h-5 hidden"
                  style={{ background: getThemes && getThemes.color1 }}
                >
                  <Link
                    className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-white-f2f9fa hover:bg-white-f2f9fa hover:text-primarycolor"
                    to="https://trustbricks.co.uk/first-time-property-investors/"
                    target={"_blank"}
                    style={{ color: getThemes && getThemes!.txtColor }}
                  >
                    New Investors
                  </Link>

                  <Link
                    className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-white-f2f9fa hover:bg-white-f2f9fa hover:text-primarycolor"
                    to="https://trustbricks.co.uk/invest-in-property/"
                    target={"_blank"}
                    style={{ color: getThemes && getThemes!.txtColor }}
                  >
                    Portfolio Investors
                  </Link>
                  <Link
                    className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-white-f2f9fa hover:bg-white-f2f9fa hover:text-primarycolor"
                    to="https://trustbricks.co.uk/refer-an-investor/"
                    target={"_blank"}
                    style={{ color: getThemes && getThemes!.txtColor }}
                  >
                    Refer an Investor
                  </Link>
                  <Link
                    className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-white-f2f9fa hover:bg-white-f2f9fa hover:text-primarycolor"
                    to="https://trustbricks.co.uk/white-label/"
                    target={"_blank"}
                    style={{ color: getThemes && getThemes!.txtColor }}
                  >
                    White Label Solution
                  </Link>
                </div>
              </div>
            )}

            {getThemes && getThemes.website === "https://trustbricks.co.uk" && (
              <Link
                className="font-medium text-left text-white-f2f9fa"
                to="https://trustbricks.co.uk/#howitworks"
                target={"_blank"}
                aria-current="page"
                style={{ color: getThemes && getThemes!.txtColor }}
              >
                How It Works
              </Link>
            )}

            <Link
              className="font-medium text-left text-white-f2f9fa"
              to="/listing"
              data-hs-collapse="#navbar-with-mega-menu"
              style={{ color: getThemes && getThemes!.txtColor }}
            >
              Properties
            </Link>

            {getThemes && getThemes.website === "https://trustbricks.co.uk" && (
              <div className="hs-dropdown [--strategy:static] sm:[--strategy:fixed] lg:[--trigger:hover] sm:[--trigger:click] [--adaptive:none] ">
                <button
                  id="hs-mega-menu-basic-dr"
                  type="button"
                  className="flex items-center w-full text-white-f2f9fa font-medium"
                  style={{ color: getThemes && getThemes!.txtColor }}
                >
                  Resources
                  <svg
                    className="ml-2 w-2.5 h-2.5 text-white-f2f9fa"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    ></path>
                  </svg>
                </button>

                <div
                  className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 sm:w-48 z-10 bg-primarycolor lg:border  sm:shadow-md rounded-lg p-2 before:absolute top-full before:-top-5 before:left-0 before:w-full before:h-5 hidden"
                  style={{ background: getThemes && getThemes.color1 }}
                >
                  <Link
                    className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-white-f2f9fa hover:bg-white-f2f9fa hover:text-primarycolor"
                    to="https://www.youtube.com/channel/UCtbHPeoFa4tQaWSJLrOsrcQ"
                    target={"_blank"}
                    style={{ color: getThemes && getThemes!.txtColor }}
                  >
                    Videos
                  </Link>
                  {}
                  <Link
                    className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-white-f2f9fa hover:bg-white-f2f9fa hover:text-primarycolor"
                    to="https://trustbricks.co.uk/blog/"
                    target={"_blank"}
                    style={{ color: getThemes && getThemes!.txtColor }}
                  >
                    Blog
                  </Link>
                </div>
              </div>
            )}
            {getToken === undefined && (
              <Link
                className="flex items-center gap-x-2 sm:py-2 font-medium text-left text-white-f2f9fa"
                to="/login"
                data-hs-collapse="#navbar-with-mega-menu"
                style={{ color: getThemes && getThemes!.txtColor }}
              >
                <svg
                  className="w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                </svg>
                Login
              </Link>
            )}
            {getToken === undefined && (
              <Link
                className="flex items-center gap-x-2 sm:py-2 font-medium text-left text-white-f2f9fa"
                to="/register"
                data-hs-collapse="#navbar-with-mega-menu"
                style={{ color: getThemes && getThemes!.txtColor }}
              >
                <svg
                  className="w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                </svg>
                Sign Up
              </Link>
            )}
            {getToken !== undefined && (
              <button
                onClick={() => logoutAction()}
                className="font-medium text-left text-white-f2f9fa"
                style={{ color: getThemes && getThemes!.txtColor }}
              >
                Log out
              </button>
            )}

            <div
              className="py-2.5 w-44 rounded-full text-white-f2f9fa font-poppins cursor-pointer"
              data-hs-overlay="#hs-large-modal"
              style={{ background: getThemes && getThemes.color2 }}
            >
              Speak To Our Team
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
