import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useTokenStore from "../store/store";
import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";
import { ResultMessageProps, verifyOTP } from "../utils/user";

type Inputs = {
  email: string;
  otp: Number;
};

function VerifyOtp() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<ResultMessageProps>({
    message: "",
    status: 0,
  });
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  useEffect(() => {
    setEmail(localStorage.getItem("email"));
  }, []);

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    setLoading(true);
    data.email = email!;
    const response = await verifyOTP(data);
    setMessage(response);
    setLoading(false);
    setTimeout(() => {
      setMessage({ message: "", status: 0 });
    }, 5000);

    if (response.status === 200) {
      navigate("/login");
    }
  };
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );
  const getToken = useTokenStore((state) => state.token);
  useEffect(() => {
    if (getToken) {
      navigate("/listing");
    }
  }, []);
  return (
    <div className="VerifyOtp">
      <Helmet>
        <title>Verify OTP Code | {getThemes && getThemes.name}</title>
      </Helmet>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-primarycolor w-full max-h-full h-[80vh] m-auto justify-center flex"
        style={{ background: getThemes && getThemes.color1 }}
      >
        <div className="w-1/5 m-auto">
          <p className="text-3xl font-roboto font-medium text-white-f2f9fa pb-8">
            Verify OTP Code
          </p>
          <div className="w-full py-2 hidden">
            <label
              htmlFor="input-label-with-helper-text"
              className="block text-base text-white-f2f9fa font-medium mb-2 text-left"
            >
              Email Address
            </label>
            <input
              type="email"
              id="input-label-with-helper-text"
              className="py-3 px-4 block w-full text-gray-600 rounded-md text-sm"
              placeholder="Email Address              "
              aria-describedby="hs-input-helper-text"
              {...register("email")}
            />
          </div>
          <div className="w-full py-2">
            <label
              htmlFor="input-label-with-helper-text"
              className="block text-base text-white-f2f9fa font-medium mb-2 text-left"
            >
              OTP Code
            </label>
            <input
              type="number"
              id="input-label-with-helper-text"
              className="py-3 px-4 block w-full border-gray-500 text-gray-600 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="OTP Code"
              aria-describedby="hs-input-helper-text"
              {...register("otp", { required: true })}
            />
            <small className="flex justify-start pt-3 text-red-hover-ce312b">
              {errors.otp && <span>OTP is required</span>}
            </small>
          </div>

          {message.status === 0 && (
            <div className="">
              <a href="">
                <button
                  type="submit"
                  className="w-full px-4 inline-flex justify-center items-center gap-2 rounded-md text-primarycolor font-semibold bg-white-f2f9fa hover:bg-gray-200 py-3"
                >
                  Verify Now
                  {/* <=====When loading show spinner=====> */}
                  {loading && (
                    <span
                      className="animate-spin border-btnscolor inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                      role="status"
                      aria-label="loading"
                    ></span>
                  )}
                </button>
              </a>
            </div>
          )}
        </div>
      </form>
      <div className="absolute bottom-[50%] left-1/2 -translate-x-1/2">
        <div
          id="dismiss-toast"
          className={`hs-removing:translate-x-5 ${
            message.status === 0 ? "hidden" : "visible"
          } hs-removing:opacity-0 transition duration-300 max-w-xs ${
            message.status === 200 ? "bg-grey-848484" : "bg-red-be1e2e"
          } rounded-md shadow-lg`}
          role="alert"
        >
          <div className="flex p-4 h-[150px] w-[250px] flex-col justify-center items-center">
            <p className="text-sm text-white-f2f9fa pr-2">{message.message}</p>
            <div className="mt-4">
              {message.status !== 200 && (
                <button
                  type="button"
                  className="inline-flex flex-shrink-0 justify-center items-center h-4 w-4 p-4 px-10 text-white-f2f9fa border-2"
                  data-hs-remove-element="#dismiss-toast"
                  onClick={() => {
                    setMessage({ message: "", status: 0 });
                  }}
                >
                  OK
                </button>
              )}
            </div>
            <div className="ml-auto"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyOtp;
